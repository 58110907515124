<template>
  <div>
    <h1 class="mb-5">{{$route.name}}</h1>
    <b-card>
    </b-card>
  </div>
</template>

<script>
import axios from '@/core/plugins/axios'
export default {
  name: "Dummy",
  components: {

  },
  data() {
    return {
      
    }
  },
  mounted() {
  },
  methods: {
    
  }
};
</script>
